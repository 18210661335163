function SelectAgent({
  lang,
  business_data,
  isLoading,
  roomStatus,
  setAssigning,
  toast,
  chatTransferButton,
}) {

  let isSmallScreen = window.matchMedia("(max-width: 768px)").matches;

  const [agents, setAgents] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [searchUserText, setSearchUserText] = React.useState("");
  const [selectedNexusDetail, setSelectedNexusDetail] = React.useState(false);
  const [selectedTeamDetail, setSelectedTeamDetail] = React.useState(false);
  const [isSelectingTeam, setIsSelectingTeam] = React.useState(true);

  React.useEffect(() => {
    getAgents();
    getTeams();
  }, []);

  React.useEffect(() => {
    setSearchUserText("");
    setIsSelectingTeam(true);
    const selectedAgent = _.find(agents, {
      nexus_user_id: parseInt(roomStatus.assign_to.nexus_user_id),
    }) || false;
    if (selectedAgent) {
      setIsSelectingTeam(false);
    }
    setSelectedNexusDetail(selectedAgent);
    setSelectedTeamDetail(_.find(teams, {team_id: roomStatus.assign_team.team_id }) || false);
  }, [roomStatus]);

  const handleClick = (event) => {
    if (
      !event.target.closest(".item.sp-row.-clickable") &&
      !event.target.closest(".sp-btn.-link")
    ) {
      event.stopPropagation();
    }
  };

  const getAgents = () => {
    axios({
      method: "POST",
      url: backend + "agent/" + business_data.uid + "/list",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
        Authorization: "Bearer " + business_data.token,
      },
      data: JSON.stringify({
        version: main.version,
        appId: business_data.app_id,
      }),
    })
      .then((res) => {
        if (res && res.data && res.data.result === 0) {
          let agentList = [];
          _.forEach(res.data.data, function (user, index) {
            if (
              user.nexus_user_id ==
              _.get(roomStatus, ["assign_to", "nexus_user_id"])
            ) {
              setSelectedNexusDetail(user);
              setIsSelectingTeam(false);
            }
            agentList.push(user);
          });

          setAgents(agentList);
        } else {
          toast.error(lang.__error);
        }
      })
      .catch((err) => {
        toast.error(lang.__error);
      });
  };

  const getTeams = () => {
    axios({
      method: "POST",
      url:
        backend +
        "team/" +
        business_data.uid +
        "/" +
        business_data.profile_id +
        "/" +
        business_data.app_id +
        "/list",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
        Authorization: "Bearer " + business_data.token,
      },
      data: JSON.stringify({
        version: main.version,
      }),
    })
      .then((res) => {
        if (res && res.data && res.data.result === 0) {
          res.data.data.forEach((team) => {
            if (
              team.team_id === _.get(roomStatus, ["assign_team", "team_id"])
            ) {
              setSelectedTeamDetail(team);
              setIsSelectingTeam(true);
            }
          });
          setTeams(res.data.data);
        } else {
          toast.error(lang.__error);
        }
      })
      .catch((err) => {
        toast.error(lang.__error);
      });
  };

  const assignTeam = (chatId, team) => {
    setAssigning({
      action: "assign",
      displayname: team.name,
    });
    axios({
      method: "POST",
      url:
        backend +
        "agent/" +
        business_data.uid +
        "/" +
        business_data.profile_id +
        "/assign-team",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
      },
      data: JSON.stringify({
        version: main.version,
        appId: business_data.app_id,
        chatId: chatId,
        teamId: team.team_id,
      }),
    })
      .then((res) => {
        if (res && res?.data && !res?.data?.result) {
          roomStatus.assign_team = team;
          roomStatus.assign_to = false;
          setSelectedTeamDetail(team);
          setSelectedNexusDetail(false);
          setAssigning(false);
          toast.success(lang.__chat_assign_to_success);
        } else {
          toast.error(lang.__chat_assign_to_fail);
        }
      })
      .catch((err) => {
        toast.error(lang.__chat_assign_to_fail);
      });
  };

  const assignAgent = (chatId, agent) => {
    setAssigning({
      action: "assign",
      displayname: agent.displayname,
    });
    axios({
      method: "POST",
      url:
        backend +
        "agent/" +
        business_data.uid +
        "/" +
        business_data.profile_id +
        "/assign-to",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
      },
      data: JSON.stringify({
        version: main.version,
        appId: business_data.app_id,
        chatId: chatId,
        nexusUserData: agent,
      }),
    })
      .then((res) => {
        if (res && res?.data && !res?.data?.result) {
          roomStatus.assign_to = {
            img_profile: agent.img_profile,
            displayname: agent.displayname,
            nexus_user_id: agent.nexus_user_id,
            email: agent.username,
          };
          roomStatus.assign_team = false;
          setSelectedNexusDetail(agent);
          setSelectedTeamDetail(false);
          chatTransferButton.transferOnly = true;
          setAssigning(false);
          toast.success(lang.__chat_assign_to_success);
        } else {
          toast.error(lang.__chat_assign_to_fail);
        }
      })
      .catch((err) => {
        toast.error(lang.__chat_assign_to_fail);
      });
  };

  return (
    <div onClick={handleClick}>
      {isSmallScreen && (
        <div>
      <div className="modal-header">
        <h3 className="modal-title">{lang.__transfer_chat_room}</h3>
      </div>
      {selectedNexusDetail && (
        <div className="item sp-row" style={{ padding: "8px" }}>
          <i
            className="icon icon-ok-circled -green"
            style={{
              fontSize: 16,
              alignContent: "center",
              marginRight: "unset",
            }}
          ></i>
          <div
            className="sp-col -wrap"
            style={{ marginLeft: "6px", alignContent: "center" }}
          >
            <div
              className="sp-logo"
              style={{
                backgroundImage: `url(${
                  selectedNexusDetail.img_profile ||
                  "../public/img/default_admin.png"
                })`,
              }}
            ></div>
          </div>
          <div className="sp-col" style={{ fontSize: 12, marginLeft: "10px" }}>
            <p className="sp-text -ellipsis">
              {selectedNexusDetail.displayname}
            </p>
            <p className="sp-text-help -ellipsis">
              {selectedNexusDetail.username}
            </p>
          </div>
        </div>
      )}

      {selectedTeamDetail && (
        <div className="item sp-row" style={{ padding: "8px" }}>
          <i
            className="icon icon-ok-circled -green"
            style={{
              fontSize: 16,
              alignContent: "center",
              marginRight: "unset",
            }}
          ></i>
          <div className="sp-col" style={{ fontSize: 12, marginLeft: "10px" }}>
            <p className="sp-text">{selectedTeamDetail.name}</p>
            <p className="sp-text-help">
              {selectedTeamDetail.team_id === "reception"
                ? lang.__team_reception_description
                : lang.__team_other_description}
            </p>
          </div>
        </div>
      )}
      </div>
      )}
      <div
        className="modal-header header"
        style={{ padding: "14px", borderTop: isSmallScreen && (selectedNexusDetail || selectedTeamDetail) ? "1px solid #e0e0e0" : "none" }}
      >
        <div className="sp-tab">
          <div
            className={`item ${isSelectingTeam ? "-selected" : ""}`}
            onClick={() => setIsSelectingTeam(!isSelectingTeam)}
          >
            {lang.__setting_reception_team}
          </div>
          <div
            className={`item ${!isSelectingTeam ? "-selected" : ""}`}
            onClick={() => setIsSelectingTeam(!isSelectingTeam)}
          >
            {lang.__setting_reception_agent}
          </div>
        </div>
      </div>
      <div className="modal-body no-padding assign-body">
        <div className="smart-invite-bar search-bar">
          <div className="sp-search">
            <i className="icon icon-search"></i>
            <input
              type="text"
              className="sp-input -borderless"
              placeholder={lang.__search}
              value={searchUserText}
              onChange={(e) => setSearchUserText(e.target.value)}
            />
          </div>
        </div>

        {isLoading && (
          <div className="sp-row loading-box">
            <div className="sp-placeholder">
              <div className="sp-text-help">
                <i className="icon icon-circle-notch animate-spin"></i>{" "}
                <span>{lang.__loading}</span>
              </div>
            </div>
          </div>
        )}
        {!isLoading && !isSelectingTeam && (
          <div className="sp-list -scrollable" style={{ maxHeight: 'unset', height: selectedNexusDetail ? 'calc(100dvh - 420px)' : 'calc(100dvh - 364px)'}}>
            {!isSmallScreen && selectedNexusDetail && (
              <div className="item sp-row" style={{ padding: "8px" }}>
                <i
                  className="icon icon-ok-circled -green"
                  style={{
                    fontSize: 16,
                    alignContent: "center",
                    marginRight: "unset",
                  }}
                ></i>
                <div
                  className="sp-col -wrap"
                  style={{ marginLeft: "6px", alignContent: "center" }}
                >
                  <div
                    className="sp-logo"
                    style={{
                      backgroundImage: `url(${
                        selectedNexusDetail.img_profile ||
                        "../public/img/default_admin.png"
                      })`,
                    }}
                  ></div>
                </div>
                <div
                  className="sp-col"
                  style={{ fontSize: 12, marginLeft: "10px" }}
                >
                  <p className="sp-text -ellipsis">
                    {selectedNexusDetail.displayname}
                  </p>
                  <p className="sp-text-help -ellipsis">
                    {selectedNexusDetail.username}
                  </p>
                </div>
              </div>
            )}
            {agents
              .filter(
                (agent) =>
                  agent.nexus_user_id != selectedNexusDetail.nexus_user_id &&
                  agent.displayname
                    .toLowerCase()
                    .includes(searchUserText.toLowerCase())
              )
              .map((agent) => (
                <div
                  key={agent.nexus_user_id}
                  className="item sp-row -clickable"
                  data-dismiss="modal"
                  onClick={() => assignAgent(roomStatus.chat_id, agent)}
                  style={{ padding: "8px", paddingLeft: "12px" }}
                >
                  <div
                    style={{
                      border: "1px solid #BBBBBB",
                      width: 18,
                      height: 14,
                      borderRadius: "50%",
                    }}
                  />
                  <div className="sp-col -wrap" style={{ marginLeft: "12px" }}>
                    <div
                      className="sp-logo"
                      style={{
                        backgroundImage: `url(${
                          agent.img_profile || "../public/img/default_admin.png"
                        })`,
                      }}
                    ></div>
                  </div>
                  <div className="sp-col" style={{ marginLeft: "12px" }}>
                    <p className="sp-text -ellipsis">{agent.displayname}</p>
                    <p className="sp-text-help -ellipsis">{agent.username}</p>
                  </div>
                </div>
              ))}
          </div>
        )}
        {!isLoading && isSelectingTeam && (
          <div className="sp-list -scrollable" style={{ maxHeight: 'unset', height: selectedTeamDetail ? 'calc(100dvh - 420px)' : 'calc(100dvh - 364px)'}}>
            {!isSmallScreen && selectedTeamDetail && (
              <div className="item sp-row" style={{ padding: "8px" }}>
                <i
                  className="icon icon-ok-circled -green"
                  style={{
                    fontSize: 16,
                    alignContent: "center",
                    marginRight: "unset",
                  }}
                ></i>
                <div
                  className="sp-col"
                  style={{ fontSize: 12, marginLeft: "10px" }}
                >
                  <p className="sp-text">{selectedTeamDetail.name}</p>
                  <p className="sp-text-help">
                    {selectedTeamDetail.team_id === "reception"
                      ? lang.__team_reception_description
                      : lang.__team_other_description}
                  </p>
                </div>
              </div>
            )}
            {teams
              .filter(
                (team) =>
                  team.team_id !== selectedTeamDetail.team_id &&
                  team.name.toLowerCase().includes(searchUserText.toLowerCase())
              )
              .map((team) => (
                <div
                  key={team.team_id}
                  className="item sp-row -clickable"
                  data-dismiss="modal"
                  onClick={() => assignTeam(roomStatus.chat_id, team)}
                  style={{ padding: "8px", paddingLeft: "12px" }}
                >
                  <div
                    style={{
                      border: "1px solid #BBBBBB",
                      width: 15,
                      height: 14,
                      borderRadius: "50%",
                    }}
                  />
                  <div className="sp-col" style={{ marginLeft: "10px" }}>
                    <p className="sp-text">{team.name}</p>
                    <p className="sp-text-help">
                      {team.team_id === "reception"
                        ? lang.__team_reception_description
                        : lang.__team_other_description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      {isSmallScreen && (
        <div className="modal-footer">
          <button
            className="sp-btn -link"
            type="button"
            data-dismiss="modal"
            onClick={() => setAssigning(false)}
          >
            {lang.__close}
          </button>
        </div>
      )}
    </div>
  );
}
